/* eslint-disable no-console */
import * as React from 'react';
import DomainResources from '../routes/types';
import getResources from '../routes';

export type ResourcesProviderType = {
  resources: DomainResources;
  setResources: (res: DomainResources) => void;
};

export const defaultState: ResourcesProviderType = {
  resources: getResources(),
  setResources: res => console.warn('No context provided', res),
};

export const ResourcesContext = React.createContext<ResourcesProviderType>(
  defaultState,
);

export const useResource = () => React.useContext(ResourcesContext);
