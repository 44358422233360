import * as React from 'react';
import { CSpinner } from '@coreui/react';
/**
 * Loading
 */
const LoadingSpinner: React.FC = () => {
  return (
    <div className="text-center py-4">
      <CSpinner />
    </div>
  );
};

export default LoadingSpinner;
