export const cilCash =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M432,64H16V384H432ZM400,352H48V96H400Z" class="ci-primary"/><polygon fill="var(--ci-primary-color, currentColor)" points="464 144 464 416 96 416 96 448 496 448 496 144 464 144" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M224,302.46c39.7,0,72-35.137,72-78.326s-32.3-78.326-72-78.326-72,35.136-72,78.326S184.3,302.46,224,302.46Zm0-124.652c22.056,0,40,20.782,40,46.326s-17.944,46.326-40,46.326-40-20.782-40-46.326S201.944,177.808,224,177.808Z" class="ci-primary"/><rect width="32" height="176" x="80" y="136" fill="var(--ci-primary-color, currentColor)" class="ci-primary"/><rect width="32" height="176" x="336" y="136" fill="var(--ci-primary-color, currentColor)" class="ci-primary"/></svg>';
export const cilWallet =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M489.972,119.059a23.839,23.839,0,0,0-17-7.059H456V72a24.027,24.027,0,0,0-24-24H86.627A70.628,70.628,0,0,0,16,118.627V393.373A70.628,70.628,0,0,0,86.627,464h385.4a24.047,24.047,0,0,0,24-23.923l.944-303.995A23.837,23.837,0,0,0,489.972,119.059ZM464.053,432H86.627A38.627,38.627,0,0,1,48,393.373V118.627A38.627,38.627,0,0,1,86.627,80H424v32H88v32H464.947Z" class="ci-primary"/><rect width="32" height="32" x="392" y="264" fill="var(--ci-primary-color, currentColor)" class="ci-primary"/></svg>';
export const cilBriefcase =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M471.232,111.731H368V16H144.232v95.731h-103A24.8,24.8,0,0,0,16.464,136.5V470.532A24.8,24.8,0,0,0,41.232,495.3h430A24.8,24.8,0,0,0,496,470.532V136.5A24.8,24.8,0,0,0,471.232,111.731ZM176.232,48H336v63.731H176.232Zm286.232,97.269v80.286l-53.177,53.176H273V232H239.464v46.731H103.177L50,225.555V145.269ZM50,461.764V272.982l39.286,39.287H239.464V359.5H273V312.269H423.178l39.286-39.287V461.764Z" class="ci-primary"/></svg>';
export const cilContact =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M472,48H40A24.028,24.028,0,0,0,16,72V440a24.028,24.028,0,0,0,24,24h88V405.178A20.01,20.01,0,0,1,138.284,387.7l91.979-51.123L200,260.919V200a56,56,0,0,1,112,0v60.919l-30.263,75.655L373.716,387.7h0A20.011,20.011,0,0,1,384,405.178V464h88a24.028,24.028,0,0,0,24-24V72A24.028,24.028,0,0,0,472,48Zm-8,384H416V405.178a52.027,52.027,0,0,0-26.738-45.451h0L321.915,322.3,344,267.081V200a88,88,0,0,0-176,0v67.081L190.085,322.3l-67.347,37.432A52.027,52.027,0,0,0,96,405.178V432H48V80H464Z" class="ci-primary"/></svg>';
export const cilGroup =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M462.541,316.3l-64.344-42.1,24.774-45.418A79.124,79.124,0,0,0,432.093,192V120A103.941,103.941,0,0,0,257.484,43.523L279.232,67a71.989,71.989,0,0,1,120.861,53v72a46.809,46.809,0,0,1-5.215,21.452L355.962,284.8l89.058,58.274a42.16,42.16,0,0,1,19.073,35.421V432h-72v32h104V378.494A74.061,74.061,0,0,0,462.541,316.3Z" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M318.541,348.3l-64.343-42.1,24.773-45.418A79.124,79.124,0,0,0,288.093,224V152A104.212,104.212,0,0,0,184.04,47.866C126.723,47.866,80.093,94.581,80.093,152v72a78,78,0,0,0,9.015,36.775l24.908,45.664L50.047,348.3A74.022,74.022,0,0,0,16.5,410.4L16,496H352.093V410.494A74.061,74.061,0,0,0,318.541,348.3ZM320.093,464H48.186l.31-53.506a42.158,42.158,0,0,1,19.073-35.421l88.682-58.029L117.2,245.452A46.838,46.838,0,0,1,112.093,224V152a72,72,0,1,1,144,0v72a46.809,46.809,0,0,1-5.215,21.452L211.962,316.8l89.058,58.274a42.16,42.16,0,0,1,19.073,35.421Z" class="ci-primary"/></svg>';
export const cilHouse =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M408,406.545V248H288V406.545ZM320,280h56v94.545H320Z" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M271.078,33.749a34,34,0,0,0-47.066.984L32,226.745V496H144V336h64V496H480V225.958ZM448,464H240V304H112V464H64V240L249.412,57.356V57.3L448,240Z" class="ci-primary"/></svg>';
export const cilPeople =
  '<svg viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M462.54 316.3l-64.34-42.1 24.77-45.42A79.12 79.12 0 00432.1 192v-72a103.94 103.94 0 00-174.6-76.48L279.22 67a71.99 71.99 0 01120.86 53v72a46.8 46.8 0 01-5.21 21.45l-38.92 71.35 89.06 58.27a42.16 42.16 0 0119.07 35.43V432h-72v32h104v-85.5a74.06 74.06 0 00-33.55-62.2z" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M318.54 348.3l-64.34-42.1 24.77-45.42A79.12 79.12 0 00288.1 224v-72A104.21 104.21 0 00184.04 47.87C126.72 47.87 80.09 94.58 80.09 152v72a78 78 0 009.02 36.77l24.9 45.67-63.96 41.86a74.02 74.02 0 00-33.55 62.1L16 496h336.1v-85.5a74.06 74.06 0 00-33.56-62.2zM320.1 464H48.2l.3-53.5a42.16 42.16 0 0119.08-35.43l88.68-58.03-39.05-71.59a46.84 46.84 0 01-5.1-21.45v-72a72 72 0 11144 0v72a46.8 46.8 0 01-5.22 21.45l-38.92 71.35 89.06 58.27a42.16 42.16 0 0119.07 35.43z" class="ci-primary"/></svg>';
export const cilUserPlus =
  '<svg viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M208 16A112.13 112.13 0 0096 128v79.68a80.24 80.24 0 009.77 38.3l27.45 50.34-72.82 47.34A79.72 79.72 0 0024 410.73V496h288v-32H56v-53.27a47.84 47.84 0 0121.84-40.24L175.5 307l-41.64-76.34a48.15 48.15 0 01-5.86-22.99V128a80 80 0 01160 0v79.68a48.15 48.15 0 01-5.86 22.99L240.5 307l71.5 46.47v-38.16l-29.22-19 27.45-50.34a80.23 80.23 0 009.77-38.3V128A112.13 112.13 0 00208 16zM424 400v-64h-32v64h-64v32h64v64h32v-64h64v-32h-64z" class="ci-primary"/></svg>';
export const cilCopy =
  '<svg id="cil-copy" viewBox="0 0 512 512"><polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" class="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" class="ci-primary"></path></svg>';
export const cilStore =
  '<svg id="cil-store" viewBox="0 0 512 512" class=""><path fill="var(--ci-primary-color, currentColor)" d="M496,237.4,443.532,80H68.468L16,237.4V272H48V496H464V272h32ZM91.532,112H420.468l42.667,128H48.865ZM144,272h88V400H144Zm-64,0h32V432H264V272h40V464H80ZM432,464H336V272h96Z" class="ci-primary"></path><rect width="368" height="32" x="72" y="16" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect></svg>';
export const cilEyeSlash =
  '<svg id="cil-eye-slash" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M397.222,131.1l-.218-.223C333.831,67.707,238.47,55.862,163.228,95.346l23.938,23.939c61.571-27.691,136.573-16.327,187.105,34.115L464,246.683v3.107l-71.744,74.585,22.63,22.63L496,262.683V233.79Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M352.8,284.33A103.307,103.307,0,0,0,219.907,151.438L246.1,177.63a71.228,71.228,0,0,1,80.507,80.508Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M369.9,347.268l-33.831-33.831c.088-.108.179-.212.266-.32l-22.805-22.806c-.083.113-.169.222-.253.334l-99.681-99.681c.112-.083.221-.17.334-.253L191.12,167.906c-.108.087-.213.179-.321.266L38.627,16H16V38.627l95.689,95.689L16,233.79v28.893l98.778,102.689.218.222A199.732,199.732,0,0,0,367.372,390l106,106H496V473.373L392.537,369.911Zm-177.157-131.9L288.871,311.5a71.28,71.28,0,0,1-96.133-96.133ZM137.729,343.073,48,249.79v-3.107l86.319-89.737,35.065,35.064A103.248,103.248,0,0,0,312.226,334.853l32.007,32.007C279.723,406.875,193.711,398.955,137.729,343.073Z" class="ci-primary"></path></svg>';
export const cilEye =
  '<svg id="cil-eye" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M256,144.927A103.309,103.309,0,1,0,359.309,248.236,103.426,103.426,0,0,0,256,144.927Zm0,174.618a71.309,71.309,0,1,1,71.309-71.309A71.39,71.39,0,0,1,256,319.545Z" class="ci-primary"></path><path fill="var(--ci-primary-color, currentColor)" d="M397.222,131.1l-.218-.223c-77.75-77.749-204.258-77.749-282.008,0L16,233.79v28.893l98.778,102.689.218.222a199.409,199.409,0,0,0,282.008,0l99-102.911V233.79ZM464,249.79l-89.732,93.285a167.409,167.409,0,0,1-236.536,0L48,249.79v-3.107L137.729,153.4c65.247-65.13,171.3-65.13,236.542,0L464,246.683Z" class="ci-primary"></path><rect width="32" height="32" x="240" y="232" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect></svg>';
export const cixX =
  '<svg id="cis-x" viewBox="0 0 512 512"><polygon fill="var(--ci-primary-color, currentColor)" points="438.627 118.627 393.373 73.373 256 210.746 118.627 73.373 73.373 118.627 210.746 256 73.373 393.373 118.627 438.627 256 301.254 393.373 438.627 438.627 393.373 301.254 256 438.627 118.627" class="ci-primary"></polygon></svg>';
export const cilHistory =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M256.25,16A240,240,0,0,0,88,84.977V16H56V144H184V112H106.287A208,208,0,0,1,256.25,48C370.8,48,464,141.2,464,255.75S370.8,463.5,256.25,463.5,48.5,370.3,48.5,255.75h-32A239.75,239.75,0,0,0,425.779,425.279,239.75,239.75,0,0,0,256.25,16Z" class="ci-primary"/><polygon fill="var(--ci-primary-color, currentColor)" points="240 111.951 239.465 288 368 288 368 256 271.563 256 272 112.049 240 111.951" class="ci-primary"/></svg>';
export const cilFilterX =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><polygon fill="var(--ci-primary-color, currentColor)" points="40 16 40 53.828 109.024 136 150.815 136 76.896 48 459.51 48 304 242.388 304 401.373 241.373 464 240 464 240 368 208 368 208 496 254.627 496 336 414.627 336 253.612 496 53.612 496 16 40 16" class="ci-primary"/><polygon fill="var(--ci-primary-color, currentColor)" points="166.403 248.225 226.864 187.763 204.237 165.135 143.775 225.597 83.313 165.135 60.687 187.763 121.148 248.225 60.687 308.687 83.313 331.314 143.775 270.852 204.237 331.314 226.864 308.687 166.403 248.225" class="ci-primary"/></svg>';
export const cilConfig =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="var(--ci-primary-color, currentColor)" d="M245.151,168a88,88,0,1,0,88,88A88.1,88.1,0,0,0,245.151,168Zm0,144a56,56,0,1,1,56-56A56.063,56.063,0,0,1,245.151,312Z" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M464.7,322.319l-31.77-26.153a193.081,193.081,0,0,0,0-80.332l31.77-26.153a19.941,19.941,0,0,0,4.606-25.439l-32.612-56.483a19.936,19.936,0,0,0-24.337-8.73l-38.561,14.447a192.038,192.038,0,0,0-69.54-40.192L297.49,32.713A19.936,19.936,0,0,0,277.762,16H212.54a19.937,19.937,0,0,0-19.728,16.712L186.05,73.284a192.03,192.03,0,0,0-69.54,40.192L77.945,99.027a19.937,19.937,0,0,0-24.334,8.731L21,164.245a19.94,19.94,0,0,0,4.61,25.438l31.767,26.151a193.081,193.081,0,0,0,0,80.332l-31.77,26.153A19.942,19.942,0,0,0,21,347.758l32.612,56.483a19.937,19.937,0,0,0,24.337,8.73l38.562-14.447a192.03,192.03,0,0,0,69.54,40.192l6.762,40.571A19.937,19.937,0,0,0,212.54,496h65.222a19.936,19.936,0,0,0,19.728-16.712l6.763-40.572a192.038,192.038,0,0,0,69.54-40.192l38.564,14.449a19.938,19.938,0,0,0,24.334-8.731L469.3,347.755A19.939,19.939,0,0,0,464.7,322.319Zm-50.636,57.12-48.109-18.024-7.285,7.334a159.955,159.955,0,0,1-72.625,41.973l-10,2.636L267.6,464h-44.89l-8.442-50.642-10-2.636a159.955,159.955,0,0,1-72.625-41.973l-7.285-7.334L76.241,379.439,53.8,340.562l39.629-32.624-2.7-9.973a160.9,160.9,0,0,1,0-83.93l2.7-9.972L53.8,171.439l22.446-38.878,48.109,18.024,7.285-7.334a159.955,159.955,0,0,1,72.625-41.973l10-2.636L222.706,48H267.6l8.442,50.642,10,2.636a159.955,159.955,0,0,1,72.625,41.973l7.285,7.334,48.109-18.024,22.447,38.877-39.629,32.625,2.7,9.972a160.9,160.9,0,0,1,0,83.93l-2.7,9.973,39.629,32.623Z" class="ci-primary"/></svg>';
