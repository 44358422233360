import { cilWallet } from 'assets/icons_strings';

import { Link } from 'app/application/routes/types';

const affiliateNav: Link[] = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/app/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Contas bancárias',
    to: '/app/bank-accounts',
    icon: {
      name: 'cil-wallet',
      content: cilWallet,
    },
  },
];
export default affiliateNav;
