import { cilHouse, cilContact } from '../../../../assets/icons_strings';

import { Link } from '../../../application/routes/types';

const userNav: Link[] = [
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Perfil',
    icon: 'cil-user',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Ver perfil',
        to: '/app/me',
        icon: {
          name: 'cil-user',
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Endereços',
        to: '/app/me/addresses',
        icon: {
          name: 'cil-house',
          content: cilHouse,
        },
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Contatos',
        to: '/app/me/contacts',
        icon: {
          name: 'cil-contact',
          content: cilContact,
        },
      },
      // Temporary Disabled
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Redes Sociais',
      //   to: '/app/me/social-accounts',
      //   icon: {
      //     name: 'cil-group',
      //     content: cilGroup,
      //   },
      // },
    ],
  },
];

export default userNav;
