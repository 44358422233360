import { Timeable } from '../../../extensions/timeable';
import { Role } from './role';
import { Address } from './address';
import { Contact } from './contact';
import { SocialAccount } from './social_account';

/**
 * @class User - Entidade Usuário
 * @property {string} id
 * @property {Date} createdAt
 * @property {Date} updatedAt
 */
export class User extends Timeable {
  public static SHOPKEEPER = 'shopkeeper';

  public static AFFILIATE = 'affiliate';

  public static ADMIN = 'admin';

  public email: string;

  public password: string;

  public emailVerifiedAt: Date | null;

  public role: Role;

  public addresses: Address[];

  public contacts: Contact[];

  public socialAccounts: SocialAccount[];

  /**
   * Cria um 'Main User' com os dados da API, faz os devidos tratamentos
   * @param {string} id
   * @param {string} email
   * @param {string} password
   * @param {string | null} emailVerifiedAt Pode ser null
   * @param {Role} role
   * @param {Array} addresses Lista de endereços
   * @param {Array} contacts Lista de Contatos
   * @param {Array} socialAccounts Lista de Contas Sociais
   * @param {string} createdAt
   * @param {string} updatedAt
   */
  constructor(
    id: string,
    email: string,
    password: string,
    emailVerifiedAt: string | null,
    role: Role,
    addresses: Address[],
    contacts: Contact[],
    socialAccounts: SocialAccount[],
    createdAt: string,
    updatedAt: string,
  ) {
    super(id, createdAt, updatedAt);

    this.email = email;
    this.password = password;
    this.emailVerifiedAt =
      emailVerifiedAt !== null ? new Date(emailVerifiedAt) : emailVerifiedAt;
    this.role = role;
    this.addresses = addresses;
    this.contacts = contacts;
    this.socialAccounts = socialAccounts;
  }
}
