import { AxiosRequestConfig } from 'axios';
import config from '../../configs';

const url = config.APP_URL_ORIGIN as string;
const path = config.API_PATH_RESOLVER as string;

const axiosconfig: AxiosRequestConfig = {
  baseURL: url + path,
  headers: { Accept: 'application/json' },
};

export default axiosconfig;
