/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type ComponentProps = {} | undefined;

/**
 * @var {React.ComponentType} component Component for rendering
 * @var {Auth} auth Auth object
 * @var {string} endpoint An optional path, instead of the default.
 */
interface PropType extends RouteProps {
  component: React.ComponentType<{}>;
  auth: boolean;
  endpoint?: string;
  componentProps?: ComponentProps;
}

const PrivateRoute: React.FC<PropType> = ({
  component: Component,
  auth,
  endpoint,
  componentProps,
  ...routeProps
}: PropType) => {
  const redirect = (match: any) => {
    return endpoint ? (
      <Redirect to={endpoint} />
    ) : (
      <Redirect to={`${match.path}/login`} />
    );
  };

  const renderComponentWithProps = (
    props: ComponentProps,
    PropedComponent: React.ComponentType<any>,
  ) => {
    return props ? <PropedComponent {...props} /> : <PropedComponent />;
  };

  return (
    <Route
      {...routeProps}
      render={({ match }) =>
        auth
          ? renderComponentWithProps(componentProps, Component)
          : redirect(match)
      }
    />
  );
};

PrivateRoute.defaultProps = {
  endpoint: '',
  componentProps: undefined,
};

export type PrivateRouteProps = PropType;
export default PrivateRoute;
