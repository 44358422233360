import React from 'react';
import { Route } from '../../../application/routes/types';

/**
 * Import with React Lazy, Code Spliting with the application views
 */
const Dashboard = React.lazy(() => import('../views/dashboard'));

const AffiliatesPending = React.lazy(
  () => import('../views/affiliates/pending'),
);
const AffiliatesAproved = React.lazy(
  () => import('../views/affiliates/approved'),
);
const AffiliateStatement = React.lazy(
  () => import('../views/affiliates/statement'),
);
const DiscountCoupons = React.lazy(() => import('../views/coupons'));
const NewDiscountCoupon = React.lazy(() => import('../views/coupons/new'));
const EditDiscountCoupon = React.lazy(() => import('../views/coupons/edit'));

const shopkeeper: Route[] = [
  { path: '/app/', exact: true, name: 'Home' },
  {
    path: '/app/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/app/affiliates',
    exact: true,
    name: 'Afiliados',
  },
  {
    path: '/app/affiliates/approved',
    name: 'Lista de afiliados',
    exact: true,
    component: AffiliatesAproved,
  },
  {
    path: '/app/affiliates/pending',
    name: 'Solicitações de afiliação',
    component: AffiliatesPending,
  },
  {
    path: '/app/affiliates/:affiliateId/statement',
    name: 'Extrato',
    component: AffiliateStatement,
  },
  {
    exact: true,
    path: '/app/discount-coupons',
    name: 'Cupons de desconto',
    component: DiscountCoupons,
  },
  {
    exact: true,
    path: '/app/discount-coupons/new',
    name: 'Novo cupom de desconto',
    component: NewDiscountCoupon,
  },
  {
    exact: true,
    path: '/app/discount-coupons/edit/:couponId',
    name: 'Editar cupom de desconto',
    component: EditDiscountCoupon,
  },
];

export default shopkeeper;
