import routes from './routes';
import links from './nav';
import DomainResources from '../../../application/routes/types';

const res: DomainResources = {
  routes,
  links,
};

export default res;
