import { createStore } from 'redux';

/* SideBar state Type */
export type SideBarState = boolean | 'responsive';

/* Sidebar state object interface */
export interface SideBarStateObj {
  sidebarShow: SideBarState;
}

/* Actions */
export const TOGGLE_SIDEBAR = 'set';
interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR;
  sidebarShow: SideBarState;
}

/* SideBar actions union ( uncessary, but might have most actions ) */
export type SideBarActionTypes = ToggleSidebarAction;

/* Sidebar toogles (Generate the state for dispatch fn) */
export function toggleSideBar(
  newSidebarShow: SideBarState,
): SideBarActionTypes {
  return {
    type: TOGGLE_SIDEBAR,
    sidebarShow: newSidebarShow,
  };
}

/* Estado inicial */
const initialState: SideBarStateObj = {
  sidebarShow: 'responsive',
};

/* Sidebar reducer */
export const sideBarReducer = (
  state = initialState,
  action: SideBarActionTypes,
): SideBarStateObj => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { sidebarShow: action.sidebarShow };
    default:
      return state;
  }
};

const store = createStore(sideBarReducer);
export default store;
