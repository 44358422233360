import React from 'react';
import { Route } from '../../../application/routes/types';

/**
 * Import with React Lazy, Code Spliting with the application views
 */
const Addresses = React.lazy(() => import('../views/addresses'));
const Contacts = React.lazy(() => import('../views/contacts'));
const Profile = React.lazy(() => import('../views/profile'));
const AddAddress = React.lazy(() => import('../views/addresses/new'));
const AddContact = React.lazy(() => import('../views/contacts/new'));
// Temporary Disabled
// const SocialAccounts = React.lazy(() => import('../views/social_accounts'));
// const AddSocialAccount = React.lazy(
//   () => import('../views/social_accounts/new'),
// );
const EditContact = React.lazy(() => import('../views/contacts/edit'));
const EditAddress = React.lazy(() => import('../views/addresses/edit'));

/**
 * Return the default routes refers to the affiliate domain
 */
const user: Route[] = [
  { exact: true, path: '/app/me/', name: 'Perfil', component: Profile },
  {
    exact: true,
    path: '/app/me/addresses',
    name: 'Endereços',
    component: Addresses,
  },
  {
    exact: true,
    path: '/app/me/addresses/new',
    name: 'Novo Endereço',
    component: AddAddress,
  },
  {
    exact: true,
    path: '/app/me/addresses/:id',
    name: 'Editar Endereço',
    component: EditAddress,
  },
  {
    exact: true,
    path: '/app/me/contacts/new',
    name: 'Novo Contato',
    component: AddContact,
  },
  {
    exact: true,
    path: '/app/me/contacts/:id',
    name: 'Editar Contato',
    component: EditContact,
  },
  // Temporary Disabled
  // {
  //   path: '/app/me/social-accounts/new',
  //   name: 'Nova Rede Social',
  //   component: AddSocialAccount,
  // },
  {
    exact: true,
    path: '/app/me/contacts',
    name: 'Contatos',
    component: Contacts,
  },
  // Temporary Disabled
  // {
  //   path: '/app/me/social-accounts',
  //   name: 'Redes Sociais',
  //   component: SocialAccounts,
  // },
];

export default user;
