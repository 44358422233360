/**
 * Entidades que possuem Id extendem
 */
export abstract class Indexable {
  /**
   * @var {string} id Ex: id: 123e4567-e89b-12d3-a456-426614174000
   */
  protected id: string;

  /**
   * Construtor de Super classe de entidades Indexáveis
   * @param {string} id
   */
  constructor(id: string) {
    this.id = id;
  }
}
