/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Api } from './client';
import env from '../configs';
import { FormData } from '../app/application/pages/login/login';

export type LoginPayload = {
  grant_type: string;
  client_id: string;
  client_secret: string;
  scope: string;
} & FormData;

export type ExternalLoginPayload = {
  access_token: string;
};

export class Login extends Api {
  /* Config */
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  /* Log In request */
  public in(payload: FormData) {
    const { client_id, client_secret } = env.CLIENT;
    let assignedPayload: LoginPayload = {
      ...payload,
      client_id: '',
      client_secret: '',
      grant_type: '',
      scope: '*',
    };
    if (client_id && client_secret) {
      assignedPayload = {
        ...assignedPayload,
        client_id,
        client_secret,
        grant_type: 'password',
      };
    }
    return this.post('/oauth/token', assignedPayload);
  }

  public externalLoginValidate(payload: ExternalLoginPayload) {
    return this.post(
      '/external/shopkeepers/affiliates/authenticate/validate',
      payload,
    );
  }

  public refreshToken(refresh_token: string) {
    const { client_id, client_secret } = env.CLIENT;

    const body = {
      grant_type: 'refresh_token',
      client_id,
      client_secret,
      refresh_token,
      scopes: '*',
    };

    return this.post('/oauth/token', body);
  }
}
