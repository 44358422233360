import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

/**
 * @class Axios client
 */
export abstract class Axios {
  /**
   * Axios instance
   */
  protected readonly instance: AxiosInstance;

  /**
   * Property to define interceptors
   */
  protected interceptors;

  /**
   * Create an Axios instance
   *
   * @param {AxiosRequestConfig} config Instance cofigurations
   */
  constructor(config: AxiosRequestConfig) {
    /* Seting the instance */
    this.instance = axios.create(config);

    /* Setting the interceptors */
    this.interceptors = this.instance.interceptors;
  }
}
