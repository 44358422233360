/* eslint-disable camelcase */
import { Api, QueryParams } from './client';

export interface CreatePayload {
  name: string;
  expiration_date: string;
  pivot_id: string;
}

export interface UpdatePayload {
  expiration_date: string;
}

export class Coupon extends Api {
  public listAll(params: QueryParams) {
    const queryParams = this.parseQueryParams(params);

    return this.get(`/coupons${queryParams}`);
  }

  public show(couponId: string) {
    return this.get(`/coupons/${couponId}`);
  }

  public create(payload: CreatePayload) {
    return this.post('/coupons', payload);
  }

  public update(couponId: string, payload: UpdatePayload) {
    return this.put(`/coupons/${couponId}`, payload);
  }

  public remove(couponId: string) {
    return this.delete(`/coupons/${couponId}`);
  }

  /** Inactivate an specfic coupon */
  public inactivate(couponId: string) {
    return this.put(`/coupons/${couponId}/inactivate`);
  }
}
