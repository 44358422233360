import { useHistory, useRouteMatch } from 'react-router-dom';
import * as React from 'react';
import LoadingSpinner from 'app/application/components/spinner';
import { store } from 'api';
import configs from 'configs';

const StoreRouter: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const getStoreUrl = async () => {
    try {
      await store.checkPage(match.url);
      window.location.href = `${configs.API_URL}/url${match.url}`;
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status >= 400) {
          history.push('/app/404');
        }
      } else {
        window.location.href = `${configs.API_URL}/url${match.url}`;
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    getStoreUrl();
  }, []);

  return <LoadingSpinner />;
};

export default StoreRouter;
