import { Link } from 'app/application/routes/types';
import { cilPeople, cilUserPlus } from 'assets/icons_strings';

const shopkeeperNav: Link[] = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/app/dashboard',
    icon: 'cil-speedometer',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Afiliados',
    to: '/app/affiliates/approved',
    icon: {
      nema: 'cil-people',
      content: cilPeople,
    },
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Solicitações de afiliação',
    to: '/app/affiliates/pending',
    icon: {
      nema: 'cil-user-plus',
      content: cilUserPlus,
    },
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Cupons de desconto',
    to: '/app/discount-coupons',
    icon: 'cil-tags',
  },
];

export default shopkeeperNav;
