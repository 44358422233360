const useProxy = process.env.REACT_APP_USE_PROXY;

const appUrlOrigin =
  useProxy === 'no' ? process.env.REACT_APP_API_URL : window.location.origin;
const apiPathResolver =
  useProxy === 'no' ? '' : process.env.REACT_APP_API_PATH_RESOLVER;

export const configs = {
  APP_URL_ORIGIN: appUrlOrigin,
  API_URL: process.env.REACT_APP_API_URL,
  API_PATH: process.env.REACT_APP_API_PATH,
  API_PATH_RESOLVER: apiPathResolver,
  CLIENT: {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  },
  SHOPKEEPER: {
    id: process.env.REACT_APP_SHOPKEEPER_ID,
    payment_type_id: process.env.REACT_APP_SHOPKEEPER_PAYMENT_TYPE_ID,
    commission_type_id: process.env.REACT_APP_SHOPKEEPER_COMMISSION_TYPE_ID,
  },
  THIRD_PARTY: {
    AUTH: {
      USER: process.env.REACT_APP_THIRD_PARTY_AUTH_USER,
      PASSWORD: process.env.REACT_APP_THIRD_PARTY_AUTH_PASSWORD,
    },
    AFL: {
      API_URL: process.env.REACT_APP_THIRD_PARTY_AFL_API_URL,
      API_PATH: process.env.REACT_APP_THIRD_PARTY_AFL_API_PATH,
      API_PATH_RESOLVER:
        process.env.REACT_APP_THIRD_PARTY_AFL_API_PATH_RESOLVER,
    },
  },
};

export default configs;
