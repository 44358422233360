import { AxiosRequestConfig } from 'axios';
import { Api } from '../client';

/* eslint-disable camelcase */
export type RenewType = {
  token: string;
  password: string;
  password_confirmation: string;
};

export type ResetType = {
  email: string;
};

export class Password extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public reset(payload: ResetType) {
    return this.post('/users/reset_password', payload);
  }

  public renew(userID: string, payload: RenewType) {
    return this.put(`/users/${userID}/password`, payload);
  }
}
