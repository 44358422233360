/* eslint-disable camelcase */
/* eslint-disable */
import { AxiosRequestConfig } from 'axios';
import { Api, QueryParams } from './client';

export class Transactions extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public listAll(pageNum?: number, queryParams: QueryParams = {}) {
    const parsedQueryParams = this.parseQueryParams({
      ...queryParams,
      page: pageNum,
      include: ['commissions'],
    });

    return this.get(`/transactions/${parsedQueryParams}`);
  }

  /**
   * Show an specific transaction
   * @param id Transaction id
   */
  public showDetails(id: string) {
    return this.get<{ data: any }>(
      `/transactions/${id}?include=items,commissions`,
    );
  }
}
