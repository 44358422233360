import * as React from 'react';
import { Route } from 'app/application/routes/types';
/**
 * Import de paginas com React Lazy, Code Spliting com as view da aplicação
 */
const Dashboard = React.lazy(() => import('../views/dashboard'));
const ShopkeepersInfo = React.lazy(
  () => import('../views/shopkeepers_list/info'),
);

const BankAccounts = React.lazy(() => import('../views/bank_accounts'));
const AddBankAccounts = React.lazy(() => import('../views/bank_accounts/new'));
const UpdateBankAccounts = React.lazy(
  () => import('../views/bank_accounts/new/update'),
);
/**
 * Return the default routes refers to the affiliate domain
 */
const affiliateRoutes: Route[] = [
  {
    path: '/app/',
    exact: true,
    name: 'Home',
  },
  {
    path: '/app/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/app/shopkeepers/info',
    name: 'Informação',
    component: ShopkeepersInfo,
  },
  {
    exact: true,
    path: '/app/bank-accounts',
    name: 'Contas Bancárias',
    component: BankAccounts,
  },
  {
    path: '/app/bank-accounts/new',
    name: 'Adicionar',
    component: AddBankAccounts,
  },

  {
    path: '/app/bank-accounts/update',
    name: 'Atualizar',
    component: UpdateBankAccounts,
  },
];
/** Inclui as rotas de user também */
export default affiliateRoutes;
