/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Api } from './client';
/* eslint-disabled camelcase */

export type AffiliateType = {
  email: string;
  password: string;
  password_confirmation: string;
  type: string;
  nickname: string;
  code: string;
};

export type JuridicalType = AffiliateType & {
  company_name: string;
  trade_name: string;
  cnpj: string;
  ie: string;
  cnpj_formatted?: string;
};

export type NaturalType = AffiliateType & {
  full_name?: string;
  first_name: string;
  last_name: string;
  cpf: string;
  cpf_formatted?: string;
  rg: string;
  birthday: string;
};

export type AffiliateRelated = JuridicalType | NaturalType;

export type RegistrationForm = JuridicalType &
  NaturalType & {
    cpf_formatted: string;
    cnpj_formatted: string;
  };

export type AffiliatePayload = AffiliateRelated & {
  role_type: string;
  role_level: string;
};

export type StoreType = {
  account_type_id: string;
  bank_id: string;
  agency: string;
  agency_digit: string | null;
  account: string;
  account_digit: string | null;
};

export type UpdateAffiliateType = {
  nickname?: string;
  code?: string;
  first_name?: string;
  last_name?: string;
  rg?: string;
  birthday?: string;
  company_name?: string;
  trade_name?: string;
  ie?: string;
};

export type AffiliateValidationPayload = {
  email?: string;
  cpf?: string;
  cnpj?: string;
  code?: string;
};

export class Affiliate extends Api {
  /* Config */
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  /* Log In request */
  public register(affiliate: AffiliateRelated) {
    const payload: AffiliatePayload = {
      ...affiliate,
      role_type: 'affiliate',
      role_level: 'super',
    };
    return this.post<AffiliatePayload>('/affiliates/register', payload);
  }

  /* Update data of affiliate */
  public update(affiliateID: string, payload: UpdateAffiliateType) {
    return this.put(`/affiliates/${affiliateID}`, payload);
  }

  public store(payload: AffiliatePayload) {
    return this.post<AffiliatePayload>('/affiliates', payload);
  }

  /* Store a new bank account to affiliate */
  public storeBank(affiliateID: string | undefined, payload: StoreType) {
    return this.post(`/affiliates/${affiliateID}/bank_accounts`, payload);
  }

  /* List Affiliate Bank Accounts  */
  public listBank(affiliateID: string | undefined, limit?: number) {
    return this.get(
      `/affiliates/${affiliateID}/bank_accounts${
        limit ? `/?limit=${limit}` : ''
      }`,
    );
  }

  /* Update a Affiliate Bank Account  */
  public updateBank(
    affiliateID: string,
    bankAccountID: string,
    payload: StoreType,
  ) {
    return this.put(
      `/affiliates/${affiliateID}/bank_accounts/${bankAccountID}`,
      payload,
    );
  }

  /* Show a specific Affiliate Bank Account */
  public showBankAccount(affiliateID: string, bankAccountID: string) {
    return this.get(
      `/affiliates/${affiliateID}/bank_accounts/${bankAccountID}`,
    );
  }

  /* Delete a specific Affiliate Bank Account */
  public deleteBankAccount(affiliateID: string, bankAccountID: string) {
    return this.delete(
      `/affiliates/${affiliateID}/bank_accounts/${bankAccountID}`,
    );
  }

  public validate(payload: AffiliateValidationPayload) {
    return this.post('/affiliates/validate', payload);
  }
}
