/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Api, QueryParams } from './client';

type Contact = {
  contact_id: string;
};

export type RegistrationPayload = {
  affiliate_id: string;
  shopkeeper_id: string;
  address_id: string;
  bank_account_id: string;
  contacts: Contact[];
};

export class Registration extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public deletePivot(registrationId: string) {
    return this.delete(`/registrations/${registrationId}`);
  }

  public register(payload: Object) {
    return this.post('/registrations', payload);
  }

  public list() {
    return this.get(`/registrations`);
  }

  public listAllAproved() {
    return this.get(`/registrations?filter[approved]=true`);
  }

  public listAllPending(queryParams: QueryParams = {}) {
    const filters = {
      ...queryParams.filters,
      pending: true,
    };

    const parsedQueryParams = this.parseQueryParams({
      ...queryParams,
      filters,
    });

    return this.get(`/registrations${parsedQueryParams}`);
  }
}
