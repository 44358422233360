/* eslint-disable lines-between-class-members */
export class Response {
  public static HTTP_OK = 200;
  public static HTTP_CREATED = 201;
  public static HTTP_ACCEPTED = 202;
  public static HTTP_BAD_REQUEST = 400;
  public static HTTP_UNAUTHORIZED = 401;
  public static HTTP_FORBIDDEN = 403;
  public static HTTP_NOT_FOUND = 404;
  public static HTTP_UNPROCESSABLE_ENTITY = 422;
  public static HTTP_INTERNAL_SERVER_ERROR = 500;
  public static HTTP_NOT_IMPLEMENTED = 501;
}
