type AccessType = {
  token?: string;
  refresh?: string;
  expire?: number;
};
/* eslint-disable camelcase */
type DataAccess = {
  token_type?: string;
  expires_in?: number;
  access_token?: string;
  refresh_token?: string;
};

export const removeAll = () => {
  localStorage.removeItem('_access');
  localStorage.removeItem('_user');
};

export const setAccess = (data: DataAccess) => {
  const { access_token, expires_in: expired, refresh_token } = data;

  const date = new Date();

  if (expired) date.setSeconds(date.getSeconds() + expired);

  const expire = date.getTime();

  const store: AccessType = {
    token: access_token,
    refresh: refresh_token,
    expire,
  };
  localStorage.setItem('_access', JSON.stringify(store));
};

export class Access {
  private data: AccessType | undefined;

  public constructor() {
    const access = localStorage.getItem('_access');
    this.data = access ? JSON.parse(access) : undefined;
  }

  public getToken(): string | undefined {
    return this.data?.token;
  }

  public getRefresh() {
    return this.data?.refresh;
  }

  public getExpire(): number {
    if (this.data?.expire === undefined) {
      return 0;
    }
    return this.data?.expire;
  }
}
