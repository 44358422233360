import { User as UserStore } from 'utils/storeUser';
import { User } from 'app/domains/users/entities/user';
import affiliate from 'app/domains/affiliates/routes';
import shopkeeper from 'app/domains/shopkeepers/routes';
import transactions from 'app/domains/transactions/routes';
import settings from 'app/domains/settings/routes';
import user from 'app/domains/users/routes';
import DomainResources, { Route, Link } from './types';
import PivotChanger from '../components/pivotChanger';

const getResources = (): DomainResources => {
  const userStore = new UserStore().user;
  const type = userStore?.role.type;
  const level = userStore?.role.level;
  let routes: Route[] = [];
  let links: Link[] = [];
  const header = PivotChanger;

  let application: DomainResources[] = [];

  // Filter the resources by type
  switch (type) {
    case User.SHOPKEEPER:
      application = application.concat(shopkeeper, transactions, settings);
      break;
    case User.AFFILIATE:
      application = application.concat(affiliate, transactions, user);
      break;
    case User.ADMIN:
      application = application.concat(user);
      break;
    default:
      application = application.concat(user);
  }

  routes = application.flatMap(v => v.routes);
  links = application.flatMap(v => v.links);

  // Filter the routes by level
  routes = routes.filter(route => {
    if (!route?.hiddenWhen) return true;
    return !route.hiddenWhen.includes(level || '');
  });

  // Filter the links by level
  links = links.filter((link: Link) => {
    if (!link?.hiddenWhen) return true;
    return !link.hiddenWhen.includes(level || '');
  });

  const resources: DomainResources = {
    routes,
    links,
    header,
  };

  return resources;
};

export default getResources;
