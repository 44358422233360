import { AxiosRequestConfig } from 'axios';
import { Api } from './client';

export class Bank extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public list(query?: string) {
    return this.get(`/banks${query ? `?${query}` : ''}`);
  }

  public listTypes() {
    return this.get('/banks/account_types');
  }

  public filterBanks(query: string) {
    return this.get(`/banks?filter[name]=${query}`);
  }

  public listTypesWithoutAccess() {
    return this.get('/account_types/noauth');
  }

  public listWithouAccess() {
    return this.get('/banks/noauth');
  }
}
