import { cilConfig } from 'assets/icons_strings';
import React from 'react';

/**
 * Import with React Lazy, Code Spliting with the application views
 */
const Settings = React.lazy(() => import('./views/settings'));

const routes = [
  {
    path: '/app/settings',
    name: 'Configurações',
    component: Settings,
    hiddenWhen: ['affiliate'],
  },
];

const links = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Configurações',
    to: '/app/settings',
    icon: {
      nema: 'cil-people',
      content: cilConfig,
    },
  },
];

export default {
  routes,
  links,
};
