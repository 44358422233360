import { Store } from './store';
import { Affiliate } from './affiliate';
import { Affiliate as Register } from './register';
import { Login } from './login';
import { User } from './user';
import config from './axios/config';
import { Bank } from './bank';
import { Address } from './user/address';
import { Contact } from './user/contact';
import { Registration } from './registration';
import { Shopkeeper } from './shopkeeper';
import { Transactions } from './transactions';
import { Commissions } from './commissions';
import { Password } from './user/password';
import { Pivot } from './pivots';
import { Coupon } from './coupon';

export const login = new Login(config);
export const user = new User(config);
export const address = new Address(config);
export const contact = new Contact(config);
export const bank = new Bank(config);
export const affiliate = new Affiliate(config);
export const registration = new Registration(config);
export const shopkeeper = new Shopkeeper(config);
export const transactions = new Transactions(config);
export const store = new Store(config);
export const commissions = new Commissions(config);
export const password = new Password(config);
export const pivots = new Pivot(config);
export const coupon = new Coupon(config);

export const v2 = {
  affiliate: new Register(config),
};
