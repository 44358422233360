import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoadingSpiner from '../../components/spinner';
import MainRouter from './main';

const AppWrapper: React.FC = () => {
  return (
    <BrowserRouter basename="/">
      <React.Suspense fallback={<LoadingSpiner />}>
        <MainRouter />
      </React.Suspense>
    </BrowserRouter>
  );
};

export default AppWrapper;
