import { AxiosRequestConfig } from 'axios';
import { UserType } from 'utils/storeUser';
import { Api } from '../client';

export class User extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getLoggedUser() {
    return this.get('/me');
  }

  public updateUser(payload: UserType) {
    return this.put('/me', payload);
  }
}
