import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppRouter from './app';
import StoreRouter from './store';

const MainRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/app" component={AppRouter} />
      <Route
        exact
        path="/:shopkeeperCode/:affiliateCode"
        component={StoreRouter}
      />
      <Redirect exact from="/" to="/app" />
      <Redirect to="/app/404" />
    </Switch>
  );
};

export default MainRouter;
