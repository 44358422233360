import * as React from 'react';
import {
  SwitchProps,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';

import {
  ResourcesContext,
  defaultState,
} from 'app/application/components/resourceProvider';
import { isLogged } from 'app/application/Auth';
import PrivateRoute from '../../components/privateRoute';

/**
 * Import the Resource provider and default value
 */

/**
 * Pages
 */
const Login = React.lazy(() => import('../../pages/login'));
const ExternalLogin = React.lazy(() => import('../../pages/external_login'));
const Dashboard = React.lazy(() => import('../../pages/dashboard'));
const NotFound = React.lazy(() => import('../../pages/404'));
const Registration = React.lazy(() => import('../../pages/registration'));
const NewRegistration = React.lazy(() => import('../../pages/newRegistration'));
const RecoverPassword = React.lazy(
  () => import('../../pages/recover_password'),
);
const RefreshYourTokenPage = React.lazy(
  () => import('../../pages/refresh_your_token'),
);

/**
 * Sub rotas de /app/
 *
 * Ex:
 * /app/login/
 */
const AppRouter: React.FC<SwitchProps> = () => {
  const match = useRouteMatch();
  const [resources, setResources] = React.useState(defaultState.resources);
  const auth = isLogged();

  return (
    <ResourcesContext.Provider value={{ resources, setResources }}>
      <Switch>
        <Route exact path={`${match.path}/404`} component={NotFound} />
        <Route path={`${match.path}/register`} component={Registration} />
        <Route path={`${match.path}/v2/register`} component={NewRegistration} />
        <Route
          exact
          path={`${match.path}/password_reset/:userID?/:tokenID?`}
          component={RecoverPassword}
        />
        <Redirect
          from={`${match.path}/password_reset`}
          to={`${match.path}/404`}
        />
        <Route exact path={`${match.path}/login`} component={Login} />
        <Route
          exact
          path={`${match.path}/login/:externalToken`}
          component={ExternalLogin}
        />
        <Route
          exact
          path={`${match.path}/refresh-your-token`}
          component={RefreshYourTokenPage}
        />
        <PrivateRoute component={Dashboard} auth={auth} />
      </Switch>
    </ResourcesContext.Provider>
  );
};

export default AppRouter;
