/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Commission as CommissionResource } from 'app/domains/commissions/entities/Commission';
import { Api } from './client';

export class Commissions extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public change(commissionId: string, payload: Partial<CommissionResource>) {
    return this.put<{ data: CommissionResource[] }>(
      `/commissions/${commissionId}`,
      payload,
    );
  }

  public cancel(commissionId: string) {
    return this.put(`/commissions/${commissionId}/cancel`);
  }
}
