import { Axios } from './axios';

export class Affiliate extends Axios {
  public register(body: Object) {
    return this.instance.post('/affiliates/register', {
      ...body,
      role_type: 'affiliate',
      role_level: 'super',
    });
  }
}
