/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Api } from './client';

export class Store extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public checkPage(urlCodes: string) {
    return this.get(`/url${urlCodes}`);
  }
}
