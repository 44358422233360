import * as React from 'react';
import { Route } from '../../../application/routes/types';

const Transactions = React.lazy(() => import('../views/transactions'));
const TransactionsDetails = React.lazy(
  () => import('../views/transactions/details'),
);

const Commissions = React.lazy(
  () => import('../../transactions/views/commissions'),
);

/**
 * Return the default routes refers to the transactions domain
 */
const transactionsRoutes: Route[] = [
  {
    path: '/app/transactions',
    exact: true,
    name: 'Transações',
    component: Transactions,
  },
  {
    path: '/app/transactions/:id',
    exact: true,
    name: 'Detalhes da transação',
    component: TransactionsDetails,
  },
  {
    path: '/app/commissions',
    name: 'Conta Corrente',
    component: Commissions,
  },
];

export default transactionsRoutes;
