import { AxiosRequestConfig } from 'axios';
import { Api } from '../client';

/* eslint-disable camelcase */
export type AddressPayload = {
  name: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  postal_code: string;
  city: string;
  state: string;
};

export type AddressResponse = AddressPayload & {
  id: string;
};

export class Address extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public store(payload: Object, userId: string) {
    return this.post(`/users/${userId}/addresses`, payload);
  }

  public list(userId: string) {
    return this.get(`/users/${userId}/addresses`);
  }

  public remove(userId: string, addressId: string) {
    return this.delete(`/users/${userId}/addresses/${addressId}`);
  }

  public show(userId: string, addressId: string) {
    return this.get(`/users/${userId}/addresses/${addressId}`);
  }

  public update(
    userId: string,
    addressId: string,
    payload: Partial<AddressPayload>,
  ) {
    return this.put(`/users/${userId}/addresses/${addressId}`, payload);
  }
}
