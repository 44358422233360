import { Access } from 'utils/storeAccess';
import { User } from 'utils/storeUser';

export const isLogged = (): boolean => {
  const { user } = new User();
  const access = new Access();
  const token = access.getToken();

  if (token !== undefined && user?.role !== undefined) {
    return true;
  }

  return false;
};
