import { AxiosRequestConfig } from 'axios';
import { Api } from '../client';

/* eslint-disable camelcase */
export type ContactModel = {
  type: ContactTypeModel;
  value: string;
};

export type ContactTypeModel = {
  name: string;
  slug: string;
};

export type ContactResponse = ContactModel & {
  id: string;
};

export type ContactPayload = {
  type: string;
  value: string;
};

export class Contact extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public store(userId: string, payload: ContactPayload) {
    return this.post(`/users/${userId}/contacts`, payload);
  }

  public show(userId: string, contactId: string) {
    return this.get(`/users/${userId}/contacts/${contactId}`);
  }

  public listAll(userId: string) {
    return this.get(`/users/${userId}/contacts`);
  }

  public listAllTypes() {
    return this.get<ContactTypeModel>('/contact_types');
  }

  public remove(userId: string, contactId: string) {
    return this.delete(`/users/${userId}/contacts/${contactId}`);
  }

  public update(
    userId: string,
    contactId: string,
    payload: Partial<ContactPayload>,
  ) {
    return this.put(`/users/${userId}/contacts/${contactId}`, payload);
  }
}
