import { cilCash } from 'assets/icons_strings';

import { Link } from 'app/application/routes/types';

const affiliateNav: Link[] = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Transações',
    to: '/app/transactions',
    icon: {
      name: 'cil-cash',
      content: cilCash,
    },
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Comissões',
    to: '/app/commissions',
    icon: 'cil-calculator',
  },
];

export default affiliateNav;
