import * as React from 'react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownToggle,
  CDropdownMenu,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { removeAll } from 'utils/storeAccess';
import { useHistory } from 'react-router-dom';
import { cilUser } from '@coreui/icons';

const PivotChanger: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    removeAll();
    history.push('/app/login');
  };

  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle className="d-flex align-items-center" caret color="info">
        <CIcon size="lg" name="cil-briefcase" content={cilUser} />
        <span className="d-sm-down-none">Move</span>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Opçoes</strong>
        </CDropdownItem>
        <CDropdownItem onClick={logout}>Logout</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};
export default PivotChanger;
