/* eslint-disable camelcase */
import { AxiosRequestConfig } from 'axios';
import { Api } from './client';

export interface Querys {
  page: number;
  filters: string;
}

export class Pivot extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public listAllApproveds(querys: Partial<Querys> = {}) {
    const { page = 0, filters = '' } = querys;

    const pageQuery = page > 0 ? `&page=${page}` : '';
    const filterQuery = filters && filters !== '' ? `&${filters}` : '';

    return this.get(
      `/pivots?filter[status]=approved${pageQuery}${filterQuery}`,
    );
  }

  public updateCommission(pivotId: string, commission_value: number) {
    return this.put(`/pivots/${pivotId}`, { commission_value });
  }
}
