import { AxiosRequestConfig } from 'axios';
import { Api } from './client';

/* eslint-disable camelcase */
export type AcceptAffiliateType = {
  commission_value: number;
  registration_id: string;
  affiliate_id: string;
  payment_type_id: string;
  commission_type_id: string;
};

export class Shopkeeper extends Api {
  public constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public listAffiliates() {
    return this.get('/pivots');
  }

  public acceptAffiliate(payload: AcceptAffiliateType) {
    return this.post('/pivots', payload);
  }

  public hasCustomFields(shopkeeperId: string) {
    return this.get(
      `/shopkeepers/${shopkeeperId}/custom_fields?filter[required]`,
    );
  }
}
