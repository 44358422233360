import { AffiliateRelated } from 'api/affiliate';

/* eslint-disable camelcase */

export type UserType = {
  id: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  role: RoleType;
  model: ModelType;
  related?: AffiliateRelated;
};

type RoleType = {
  id: string;
  type: string;
  level: string;
};

type ModelType = {
  id: string;
  code: string;
  created_at: string;
  model: string | null;
  nickname: string;
  type: string;
  update_at: string;
  related: AffiliateRelated;
  shopkeeper: {
    code: string;
  };
};

export const setUser = (data: UserType) => {
  localStorage.setItem('_user', JSON.stringify(data));
};

export class User {
  public user: UserType;

  public constructor() {
    const user = localStorage.getItem('_user');
    this.user = user ? JSON.parse(user) : undefined;
  }

  get getRole(): RoleType {
    return this.user?.role;
  }

  get getModel(): ModelType {
    return this.user.model;
  }

  get getCode(): string {
    if (this.user.role?.type === 'shopkeeper')
      return this.user.model.shopkeeper.code;
    if (this.user.role.type === 'affiliate') return this.user.model.code;
    throw new Error(
      `User code not found for given "${this.user.role.type}" role type`,
    );
  }
}
