import { Indexable } from './indexable';

/**
 * @class Timeable - Define campos de data retornados pela API
 * @property {string} id Id herdado
 */
export abstract class Timeable extends Indexable {
  /**
   * @var {Date} created_at Retornado pela API
   */
  protected createdAt: Date;

  /**
   * @var {Date} updated_at Retornado pela API
   */
  protected updatedAt: Date;

  /**
   * Construtor de Super classe Timeable
   * @param {string} id
   * @param {string} created_at String no formato UTC Data e hora combinadas Padrão ISO 8601
   * @param {string} updated_at String no formato UTC Data e hora combinadas Padrão ISO 8601
   */
  constructor(id: string, createdAt: string, updatedAt: string) {
    super(id);
    this.updatedAt = Timeable.stringToDate(createdAt);
    this.createdAt = Timeable.stringToDate(updatedAt);
  }

  public static isValidDateString(date: string) {
    if (Number.isNaN(Date.parse(date))) return false;
    return true;
  }

  public static stringToDate(date: string): Date {
    if (Timeable.isValidDateString(date)) return new Date(date);
    return new Date();
  }
}
