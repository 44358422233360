/* eslint-disable class-methods-use-this */
/* eslint-disable prettier/prettier */
/* eslint-disable */
import axios, { AxiosRequestConfig } from 'axios';
import { Access, setAccess, removeAll } from 'utils/storeAccess';
import configs from 'configs';
import { Response } from 'app/application/helpers/HttpResponseStatuses';
import { Axios } from './axios';

export interface QueryParams {
  filters?: {};
  page?: number;
  limit?: number;
  include?: string[];
}

/* eslint-disable camelcase */

/**
 * @class Api is the client wich all domains will extends from
 *
 * @param {AxiosRequestConfig} config - Axios request config.
 * @link [AxiosRequestConfig](https://github.com/axios/axios#request-config)
 * @extends Axios
 */

export abstract class Api extends Axios {
  protected get: typeof axios.get;

  protected post: typeof axios.post;

  protected put: typeof axios.put;

  protected patch: typeof axios.patch;

  protected delete: typeof axios.delete;

  protected request: typeof axios.request;

  private withoutAccess(status: number) {
    return [
      Response.HTTP_UNAUTHORIZED,
      Response.HTTP_FORBIDDEN,
    ].includes(status)
  }

  private isTokenOutdate() {
    const access = JSON.parse(localStorage.getItem("_access") || "{}");

    if (!access?.expire)
      return false;

    return new Date(access.expire) <= new Date();
  }

  private hasTokenExpired(error: any) {
    return !!error?.response
      && this.withoutAccess(error.response.status)
      && this.isTokenOutdate()
  }


  /**
   * Creates an instance of Api client.
   *
   * @param {AxiosRequestConfig} config
   */
  public constructor(config: AxiosRequestConfig) {
    super(config);

    /* Binding the axios methods */
    this.get = this.instance.get.bind(this);
    this.post = this.instance.post.bind(this);
    this.put = this.instance.put.bind(this);
    this.patch = this.instance.patch.bind(this);
    this.delete = this.instance.delete.bind(this);
    this.request = this.instance.request.bind(this);

    /* Setting the handleRequest */
    this.interceptors.request.use(this.handleRequest, this.handleError);
    /* Setting the handleResponse */
    this.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (this.hasTokenExpired(error)) {
          removeAll()
          // @ts-ignore
          window.location = "/app/login";
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * Assign the token for all requests
   *
   * @param {AxiosRequestConfig} conf
   */
  private handleRequest = (config: AxiosRequestConfig) => {
    const access = new Access();
    const token = access.getToken();
    if (token !== undefined) {
      const headerConfig: AxiosRequestConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return headerConfig;
    }
    return config;
  };

  /**
   * Handle the Error
   */
  private handleError = (error: any) => Promise.reject(error);

  private parseFiltersToQueryParams(filters: {}) {
    const filterEntries = Object.entries(filters);

    const filterQueries = filterEntries.map(([key, value]) => {
      return `filter[${encodeURIComponent(key)}]=${encodeURIComponent(String(value))}`;
    });

    const queryString = filterQueries.join('&');
    return queryString;
  }

  private parsePageToQueryParams(page: number) {
    return `page=${String(page)}`;
  }

  private parseLimitToQueryParams(limit: number) {
    return `limit=${String(limit)}`;
  }

  private parseIncludeToQueryParams(items: string[]) {
    const escapedItems = items.map(item => encodeURIComponent(item));
    const joinedItems = escapedItems.join(',');
    return `include=${joinedItems}`;
  }

  private isValidFilterObject(filters: Object | undefined | null): filters is Object {
    return Object.keys(filters || {}).length > 0;
  }

  protected parseQueryParams(queryParams: QueryParams) {
    const { filters, page, limit, include } = queryParams;

    if (!this.isValidFilterObject(filters) && !page && !limit && !include) {
      return '';
    }

    const params: string[] = [];
    if (this.isValidFilterObject(filters)) params.push(this.parseFiltersToQueryParams(filters));
    if (page) params.push(this.parsePageToQueryParams(page));
    if (limit) params.push(this.parseLimitToQueryParams(limit));
    if (include) params.push(this.parseIncludeToQueryParams(include));

    const resultingQuery = params.join('&');

    return `?${resultingQuery}`;
  }
}
